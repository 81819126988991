<template>
  <ul class="topic">
    <li v-for="(item, index) in hots" :key="index" @click="detail(item)">
      <van-image lazy-load round :src="item.image || item.avatar" fit="cover">
        <template v-slot:default>
          <span class="vip-icon" v-if="item.is_vip == 1 && item.is_free == 1"
            >VIP</span
          >
          <span v-if="item.lessonCount > 1" class="lessonCount"
            >{{ item.lessonCount }} {{ checkType(item.type) }}</span
          >
          <!-- <span class="orderCount" v-if="item.orderCount">
            {{ item.orderCount }} ادام ساتىپ الدى
          </span> -->
        </template>
      </van-image>
      <p class="cate_name">{{ item.title }}</p>
      <p
        class="price"
        v-if="
          (item.is_vip == 1 && item.is_free == 1 && isShowPrice) ||
          (item.is_vip == 2 && item.is_free == 1)
        "
      >
        <template>
          <span>
            <i style="margin-left: 10px" class="icon-cny" />
            {{ item.price | formatPrice(item, "price") }}
          </span>
          <span class="oldPrice" v-if="item.price !== item.old_price">
            <i style="margin-left: 6px" class="icon-cny" />
            {{ item.old_price | formatPrice(item, "old_price") }}
          </span>
          <div
            class="total_num views"
            style="direction: ltr; font-family: font-family: Arial, Helvetica, sans-serif; color: #999"
          >
            <van-icon style="margin-right: 4px" name="browsing-history-o" />
            <span class="time">{{ item.views }}</span>
          </div>
          <!-- <div class="total_num" v-if="item.orderCount">
            {{ item.orderCount }} ادام ساتىپ الدى
          </div> -->
        </template>
      </p>
      <p class="price" v-if="item.is_free == 2">
        <template>
          <span style="font-family: 'Kerwen Tor'; margin-left: 10px">
            اقىسىز</span
          >
          <div
            v-if="!item.orderCount"
            class="total_num views"
            style="direction: ltr; font-family: font-family: Arial, Helvetica, sans-serif; color: #999"
          >
            <van-icon style="margin-right: 4px" name="browsing-history-o" />
            <span class="time">{{ item.views }}</span>
          </div>
        </template>
      </p>
    </li>
  </ul>
</template>

<script>
import { getItem } from '@/utils/storage'
export default {
  props: {
    hots: {
      type: Array
    },
    clickType: {
      type: String,
      default: 'course'
    }
  },
  data () {
    return {
      isShowPrice: ''
    }
  },

  created () {
    this.isShowPrice = !getItem('vipStatus')
  },

  filters: {
    formatPrice (value, data, type) {
      // 如果有限购活动执行以下代码
      if (data.seckill_day) {
        const limitedTime = data.seckill_day
          .split('&')[1]
          .toString()
          .replace(/-/g, '/')
        const vipTime = new Date(limitedTime).getTime() / 1000 + ' '
        const nowTime = new Date()
        const endTime = new Date(vipTime * 1000)
        const t = endTime.getTime() - nowTime.getTime()
        if (t > 0) {
          switch (type) {
            case 'price':
              return data.seckill_price
            case 'old_price':
              return data.price
          }
        } else {
          return value
        }
      } else {
        return value
      }

      // let l = 0
      // let newPrice = parseInt(data.price)
      // while (newPrice >= 1) {
      //   newPrice = newPrice / 10
      //   l++
      // }

      // if (l >= 3 && data.orderCount > 0) {
      //   return parseInt(data.price)
      // } else {
      //   return data.price
      // }
    }
  },

  methods: {
    detail ({ id, type, lessonCount }) {
      if (lessonCount === 0) {
        this.$toast('قازىرشە مازمۇن قوسىلمادى')
        return
      }
      let path = ''
      if (this.clickType === 'course') {
        path = { name: 'detail', params: { parent_id: id, type } }
      } else {
        path = {
          path: '/topic',
          query: {
            id
          }
        }
      }
      this.$router.push(path)
      document.documentElement.scrollTop = 0
    },

    checkType (type) {
      let name = ''
      switch (type) {
        case 'article':
          name = 'ماقالا'
          break
        case 'audio':
          name = 'اۋديو'
          break
        case 'video':
          name = 'ۆيديو'
          break
      }
      return name
    }
  }
}
</script>

<style lang="less" scoped>
// 专题
.topic {
  display: flex;
  flex-wrap: wrap;
  padding-top: 20px;
  background-color: #fff;
  direction: rtl;
  li {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 50%;
    margin: 0;
    text-align: center;
    /deep/ .van-image {
      width: 94%;
      height: 200px;
      border-radius: 6px !important;
      object-fit: cover;
      margin-bottom: 14px;
      vertical-align: top;
    }

    .cate_name {
      flex: 1;
      font-size: 28px;
      text-align: center;
      padding: 0 20px;
      color: #3a3a3a;
      margin-bottom: 10px;
    }

    .price {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      width: 100%;
      direction: ltr;
      margin-bottom: 14px;
      > span {
        font-size: 28px;
        color: #ee0a24;
        font-weight: 600;
        font-family: Arial, Helvetica, sans-serif;
      }

      .oldPrice {
        text-decoration: line-through;
        font-size: 20px;
        color: #999;
        font-weight: normal;
      }

      .total_num {
        direction: rtl;
        color: #ee0a24;
        font-size: 22px;
        padding: 0 15px 0 0;
        margin-left: auto;
      }
    }

    .title-container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }
}
</style>
